import React from 'react';
import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { useState } from 'react';
import { Heading } from '../Heading';
import { useFetchAreaByProvinceCode, useFetchListProvinces } from '@/hooksApi/useRegion';
import { PickupTypeEnum } from '@/types';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const PickUpTypeList = [
  {
    title: 'pickup_address_type.COD_drop_off_address',
    value: PickupTypeEnum.COD,
  },
  {
    title: 'pickup_address_type.warehouse',
    value: PickupTypeEnum.WAREHOUSE,
  },
];

interface AddLocationInfoProps {
  title: string;
  locationNamePlaceholder: string;
  regionPlaceholder: string;
  typePlaceholder?: string;
  cityPlaceholder: string;
  addressPlaceholder: string;
  form: FormInstance;
  submitted: boolean;
}

const AddLocationInfo: React.FC<AddLocationInfoProps> = ({
  title,
  locationNamePlaceholder,
  regionPlaceholder,
  typePlaceholder,
  cityPlaceholder,
  addressPlaceholder,
  submitted,
  form,
}) => {
  const [selectedProvince, setSelectedProvince] = useState<string | null>(null);

  const { t } = useTranslation();

  const handleRegionChange = (value: string) => {
    setSelectedProvince(value);
  };

  const { data: listProvinces, isLoading: isLoadingProvince } = useFetchListProvinces();
  const { data: listArea, isInitialLoading: isLoadingArea } = useFetchAreaByProvinceCode(
    form?.getFieldValue('province') || selectedProvince || '',
  );

  const filterOptions = (input: string, option: any) => {
    const optionChildren = option.children;
    return optionChildren.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <div className='flex flex-col'>
      <Heading
        title={title}
        fontSize='text-lg'
        fontWeight='font-bold'
        extraClassName='uppercase leading-[20.48px]'
      />
      <Row gutter={[24, 16]} className='mt-3'>
        <Col lg={12} md={24} xs={24}>
          <Form.Item name='title' rules={[{ required: true, message: '' }]}>
            <Input
              variant='borderless'
              placeholder={`${locationNamePlaceholder}*`}
              className='border custom-select-placeholder border-solid relative border-customGrayShade4 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg w-full'
            />
          </Form.Item>
        </Col>
        <Col lg={12} md={24} xs={24}>
          <Form.Item name='type' rules={[{ required: true, message: '' }]}>
            <Select
              defaultValue={'WAREHOUSE'}
              placeholder={
                <Heading
                  title={`${typePlaceholder}*`}
                  color='text-customGrayShade4'
                  fontSize='text-sm'
                />
              }
              className='custom-select-item custom-select-placeholder h-[56px] rounded-[10px]'
            >
              {PickUpTypeList?.map((type, index) => (
                <Option key={index} value={type.value}>
                  {t(type?.title)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 16]} className='-mt-3'>
        <Col lg={12} md={24} xs={24}>
          <Form.Item name='province' rules={[{ required: true, message: '' }]}>
            <Select
              placeholder={
                <Heading
                  title={`${regionPlaceholder}*`}
                  color='text-customGrayShade4'
                  fontSize='text-sm'
                />
              }
              onChange={handleRegionChange}
              loading={isLoadingProvince}
              showSearch
              filterOption={filterOptions}
              className='custom-select-item custom-select-placeholder h-[56px] rounded-[10px]'
            >
              {listProvinces?.object?.map((region, index) => (
                <Option key={index} value={region.province_code}>
                  {region?.province}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={12} md={24} xs={24}>
          <Form.Item name='region_uid' rules={[{ required: true, message: '' }]}>
            <Select
              placeholder={
                <Heading
                  title={`${cityPlaceholder}*`}
                  color='text-customGrayShade4'
                  fontSize='text-sm'
                />
              }
              loading={isLoadingArea}
              showSearch
              filterOption={filterOptions}
              className='custom-select-item custom-select-placeholder h-[56px] rounded-[10px]'
              disabled={!selectedProvince && !form?.getFieldValue('province')}
            >
              {listArea?.object?.map((area, index) => (
                <Option key={index} value={area?.uid}>
                  {area?.area_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 16]} className='-mt-3'>
        <Col lg={24} md={24} xs={24}>
          <Form.Item name='address_text' rules={[{ required: true, message: '' }]}>
            <Input
              placeholder={`${addressPlaceholder}*`}
              className='border custom-select-placeholder border-solid border-customGrayShade4 placeholder:text-GrayShade4 placeholder:font-[400] placeholder:text-[16px] placeholder:leading-[20.48px] h-[56px] rounded-lg w-full'
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default AddLocationInfo;
