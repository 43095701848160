import { usePreviewOrderPrice } from '@/hooksApi/useOrder';
import { BasicProduct, Order, Preview, ProductDetails } from '@/types';
import { attachCountryCodeToPhone } from '@/utils';
import { FormInstance, message } from 'antd';
import { Store } from 'antd/es/form/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';

const orderPriceDefaultValue = {
  orders_value: 0,
  orders_value_with_shipment_fee: 0,
  orders_shipment_fee: 0,
  orders_value_with_platform_shipment_fee: 0,
  orders_custom_shipment_fee: 0,
};
enum RequiredFieldsEnum {
  API_VALIDATION_ERROR = 0,
  CONTACT = 'please_fill_customer_details',
  PICKUP_ADDRESS = 'please_select_pickup_address',
  PRODUCT = 'please_add_at_least_one_product',
  PHONE_NUMBER = 'please_enter_valid_phone_number',
}

const useUpdateOrderPricePreview = (productsStore: BasicProduct[], form: FormInstance<any>) => {
  {
    /*
   ? IMPROVEMENTS:
      debouncing onChange function of phoneNumber and name inputs 
    */
  }
  const [customShipmentFee, setCustomShipmentFee] = useState<number | undefined>(undefined);

  const [orderPrice, setOrderPrice] = useState<Preview>(orderPriceDefaultValue);
  const [invalidFields, setInvalidFields] = useState<Set<RequiredFieldsEnum>>(new Set());

  const { mutate: previewOrderPrice, isLoading: orderPriceLoading } = usePreviewOrderPrice();

  const { t } = useTranslation();

  const transformValuesToOrder = (values: Store, ready_to_pick_up?: boolean) => {
    const primaryPhoneNumber = values?.phoneNumberPrimary
      ? attachCountryCodeToPhone(values?.phoneNumberPrimary, values?.countryCodePrimary)
      : undefined;

    const secondaryPhoneNumber = values?.secondaryPhoneNumber
      ? attachCountryCodeToPhone(values?.secondaryPhoneNumber, values?.countryCodeSecondary)
      : undefined;

    const productList: ProductDetails[] = values.products ?? productsStore ?? [];
    const orderData: Order = {
      fee_customer_payable:
        values?.CustomerOrMerchant === 'BY_CUSTOMER'
          ? (values.customShipmentFee ?? customShipmentFee)
          : undefined,
      is_fragile: values?.isFragile || false,
      ready_to_pick_up: !!ready_to_pick_up,
      shipment_fee_type: values?.CustomerOrMerchant || 'BY_MERCHANT',
      payment_type: values?.CodOrPrepaid || 'COLLECT_ON_DELIVERY',
      pick_up_type: values?.pickupOrDropoff || 'PICK_UP',
      description: values?.deliveryInstruction || '',
      custom_id: values?.orderId || '',
      products: productList.map((product) => ({
        title: product?.title || '',
        price: Number(product?.price) || 0,
        quantity: Number(product?.quantity) || 1,
      })),
      saved_pick_up_address_uid: values?.saved_pick_up_address_uid || '',
      size: values?.packageSize || 'S',
      contact: {
        full_name: values?.name || '',
        address_text: values?.address || '',
        phone: primaryPhoneNumber ?? '',
        additional_phones: secondaryPhoneNumber ? [secondaryPhoneNumber] : [],
        region_uid: values?.region_uid || '',
      },
    };
    if (values.freezed_pick_up_address_title) {
      // if freezed_pick_up_address_title has value =SO=> the order status is not new or undefined

      //setting pick_up_address_uid
      orderData.pick_up_address_uid = values?.saved_pick_up_address_uid || '';
    } else {
      //setting saved_pick_up_address_uid
      orderData.saved_pick_up_address_uid = values?.saved_pick_up_address_uid || '';
    }
    return orderData;
  };

  const getInvalidFieldsMessage = (orderPayload: Store, checkProduct?: boolean) => {
    const invalidFields: RequiredFieldsEnum[] = [];

    const primaryPhoneNumber = orderPayload?.phoneNumberPrimary
      ? attachCountryCodeToPhone(orderPayload?.phoneNumberPrimary, orderPayload?.countryCodePrimary)
      : undefined;
    const isPhoneValid = primaryPhoneNumber ? isValidPhoneNumber('+' + primaryPhoneNumber) : false;

    const isContactValid = !!(
      (orderPayload?.name && primaryPhoneNumber && orderPayload?.address)
      //  && orderPayload?.region_uid
    );
    const isPickupAddressValid =
      !!orderPayload.saved_pick_up_address_uid || !!orderPayload.freezed_pick_up_address_title;
    const isProductValid = (orderPayload.products ?? productsStore ?? []).length > 0;

    if (!isContactValid) invalidFields.push(RequiredFieldsEnum.CONTACT);
    if (!isPickupAddressValid) invalidFields.push(RequiredFieldsEnum.PICKUP_ADDRESS);
    if (!isProductValid && checkProduct) invalidFields.push(RequiredFieldsEnum.PRODUCT);
    if (!isPhoneValid) invalidFields.push(RequiredFieldsEnum.PHONE_NUMBER);
    return invalidFields;
  };

  const handleValidationError = (newInvalidFields: RequiredFieldsEnum[]) => {
    newInvalidFields.forEach((field) => {
      if (field) message.error(t(field));
    });
  };

  const checkOrderFormValidation = (orderPayload: Store, checkProduct?: boolean) => {
    const invalidFieldList = getInvalidFieldsMessage(orderPayload, checkProduct);
    handleValidationError(invalidFieldList);

    return invalidFieldList.length === 0;
  };

  const updateOrderPrice = async (formData: Store): Promise<Preview> => {
    const orderPayload = transformValuesToOrder(formData);

    return new Promise((resolve) => {
      previewOrderPrice(orderPayload, {
        onSuccess: (response) => {
          const newOrderPrice = { ...response.object };
          resolve(newOrderPrice);
          setInvalidFields(new Set());
        },
        onError: () => {
          invalidFields.add(RequiredFieldsEnum.API_VALIDATION_ERROR);
          setInvalidFields(
            invalidFields,
            // new Set([...Array.from(invalidFields), RequiredFieldsEnum.API_VALIDATION_ERROR]),
          );
          resolve(orderPriceDefaultValue);
        },
      });
    });
  };

  const handleChangeCustomShipmentFee = (value?: number, skipFormUpdate?: boolean) => {
    setCustomShipmentFee(value);

    if (!skipFormUpdate) {
      const formValues = form.getFieldsValue();
      handleFormUpdated({ customShipmentFee: value }, { ...formValues, customShipmentFee: value });
    }
  };

  const handleProductChanged = (products: ProductDetails[]) => {
    const formValues = form.getFieldsValue();
    handleFormUpdated({ products }, { ...formValues, products });
  };

  const setPackageSize = (size: string) => {
    const formValues = form.getFieldsValue();
    handleFormUpdated({ packageSize: size }, formValues);
  };

  const shouldUpdatePrice = (changedValues: any): boolean => {
    // recall fields: products, region_uid,province, customShipmentFee,"isFragile"

    const keysToCheck = [
      'products',
      'customShipmentFee',
      'province',
      'region_uid',
      'isFragile',
      'packageSize',
      'saved_pick_up_address_uid',
    ];
    const changedItem = Object.keys(changedValues)[0];
    return (
      keysToCheck.includes(changedItem) ||
      (!!customShipmentFee && changedItem === 'CustomerOrMerchant')
    );
  };

  const handleFormUpdated = async (changedValues: any, allValues: Store) => {
    if (Object.keys(allValues).length === 0) return;

    let newOrderPrice = orderPriceDefaultValue;

    const currentInvalidFields = getInvalidFieldsMessage(allValues, true);
    const shouldBeCalled = shouldUpdatePrice(changedValues);

    //scenario =>  if we should update but also we had invalid fields => if user changed the invalid field=> update

    if (shouldBeCalled) {
      if (currentInvalidFields.length === 0) {
        // formData is valid
        newOrderPrice = await updateOrderPrice(allValues);
      } else {
        // if we should update but formData is invalid field => if user changed the invalid field=> update
        setInvalidFields(new Set(currentInvalidFields));
        handleValidationError(currentInvalidFields);
      }
      setOrderPrice(newOrderPrice);
    } else if (currentInvalidFields.length === 0 && invalidFields.size > 0) {
      newOrderPrice = await updateOrderPrice(allValues);
    }
  };

  return {
    orderPrice,
    setOrderPrice,
    orderPriceLoading,
    handleFormUpdated,
    customShipmentFee,
    setCustomShipmentFee: handleChangeCustomShipmentFee,
    setPackageSize,
    transformValuesToOrder,
    checkOrderFormValidation,
    handleProductChanged,
  };
};

export default useUpdateOrderPricePreview;
